@import 'src/scss/mixins';

.discountList {
  margin: size(20) size(50) 0 size(50);
  padding: 0 size(75);
}

.prevArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 50%;
  svg {
    width: size(50);
    height: size(50);
  }
}

.nextArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 50%;
  svg {
    width: size(50);
    height: size(50);
  }
}
