@import 'src/scss/mixins';
@import 'src/scss/variables';

.header {
  width: size($container-width);
  margin: size(60) auto 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  div {
    display: inline-block;
  }
}

.back {
  width: size(500);
  display: flex !important;
  button {
    margin-right: size(20);
  }
}

.search {
  width: size(550);
}

.logo {
  img {
    width: size(322);
    height: size(74);
  }
}
