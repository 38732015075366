@import 'src/scss/mixins';
@import 'src/scss/variables';

.productStock {
  background-color: white;
  border-radius: size(15);
  box-shadow: -1px 3px 9px -5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -1px 3px 9px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 3px 9px -5px rgba(0, 0, 0, 0.5);
  padding: 0 size(20);
  height: size(55);
  margin-bottom: size(10);
  position: relative;
  display: flex;
}

.logo {
  display: inline-block;
  img {
    margin-top: size(10);
    width: size(130);
  }
}

.location {
  display: inline-block;
  line-height: size(55);
  background: transparent url('./assets/marker.svg') no-repeat left center;
  background-size: size(20) size(20);
  padding-left: size(30);
  margin-left: size(50);
  font-weight: 600;
  font-size: 1.1rem;
  width: size(200);
}

.address {
  display: inline-block;
  font-size: 1.1rem;
  line-height: size(55);
}

.amount {
  display: inline-block;
  position: absolute;
  top: size(12);
  right: size(80);
  min-width: size(30);
  padding: 0 size(5);
  height: size(30);
  line-height: size(30);
  text-align: center;
  font-weight: 700;
  background-color: $yellow;
  font-size: 1.2rem;
}

.toggle {
  display: block;
  font-weight: 700;
  color: $base-color;
  text-decoration: underline;
  text-transform: uppercase;
  margin-top: size(20);
  text-align: right;
  font-size: 1rem;
}
