@import 'src/scss/mixins';
@import 'src/scss/variables';

.imageSlider {
  margin-bottom: size(20);
}

.imageSlide {
  background-color: white;
  border-radius: size(50);
  padding: size(110) size(50);
  height: size(650);
  margin-top: size(10);
  img {
    height: size(430);
    width: size(430);
  }
}

.prevArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  left: size(20);
  top: size(300);
  svg {
    width: size(50);
    height: size(50);
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}

.nextArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: size(20);
  top: 50%;
  svg {
    width: size(50);
    height: size(50);
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}

.thumbnailSlider {
  width: 100%;
}

.thumbnailSlide {
  background-color: white;
  width: size(104) !important;
  border-radius: size(20);
  border: 2px solid white;
  padding: size(10);
  img {
    width: size(80);
    height: size(80);
  }
}

.current {
  border: 2px solid $base-color;
}
