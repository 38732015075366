$container-width: 1800;
$base-color: #8e3193;
$dark-violet: #3f1941;
$gray: #747474;
$light-gray: #828282;
$lighter-gray: #e2e2e2;
$lightest-gray: #f4f4f4;
$dark-gray: #333;
$yellow: #ffde00;
$light-pink: #eee0ef;
