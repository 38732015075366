@import 'src/scss/variables';
@import 'src/scss/mixins';

.header {
  background-color: $lightest-gray;
  height: size(90);
  line-height: size(90);
  width: 100%;
}

.headerContainer {
  background-color: $lightest-gray;
  width: size($container-width);
  height: size(90);
  line-height: size(90);
  margin: 0 auto;
  display: flex;
  > h1 {
    flex-grow: 1;
    margin: 0;
    font-size: 2.2rem;
    text-transform: uppercase;
    > span {
      padding: 0 size(10);
      color: $base-color;
    }
    > small {
      font-size: 1.6rem;
      > span {
        color: $base-color;
      }
    }
  }
}

.productList {
  width: size($container-width);
  margin: size(20) auto 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.drawer {
  background-color: transparent !important;
}
