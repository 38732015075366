@import 'src/scss/mixins';
@import 'src/scss/variables';

.categoryList {
  display: flex;
  flex-flow: row wrap;
}

.emptyCategory {
  font-size: 3rem;
  height: size(500);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 size(50) 0;
  color: $base-color;
}
