@import 'variables';
@import 'mixins';

/* Works on Firefox */
* {
  scrollbar-width: size(30);
  scrollbar-color: $base-color $lighter-gray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: size(30);
}

*::-webkit-scrollbar-track {
  background: $lighter-gray;
  border-radius: size(20);
}

*::-webkit-scrollbar-thumb {
  background: $base-color
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAcCAYAAABRVo5BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjQ1MTk4QTg4OEMxMTFFQkEwNzFDMUNFQUM0MjBCRkYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjQ1MTk4QTk4OEMxMTFFQkEwNzFDMUNFQUM0MjBCRkYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNDUxOThBNjg4QzExMUVCQTA3MUMxQ0VBQzQyMEJGRiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNDUxOThBNzg4QzExMUVCQTA3MUMxQ0VBQzQyMEJGRiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsGPWmIAAAFTSURBVHjaYnz06BEDDuABxH+BeDc2SRYcmkDivVCNhlAaBTDh0JgGxFpArAvEydgUMGJxqgAQ3wJiUSj/FRCrAvEnQjbWIGkCATEgriZkozIQXwVidjR1P6BOv4/Lxi4smkCAA4g7cTnVHoiDGHCDECC2QdcIovsY8ANGqBpGZI1xQGzEQBiYAnEMLHB4gPRNIJZiIA48AWJ1kI2lJGgCARmQHlDSug3E6Qykga8gjUsYyABMDGQCkI2yQMxKor6/II15QFxCosapoOjgAzLuoCVsfOADEKswQbNLDQm2NQLxW1jgzAXiS0RoAiWUqcihCioaConQCAqL3+jRsQ+IN+DRtAuIt+CKxzIg/olF0x8gLsaXAEDJbwoWjbOA+AqhwoofaoAoUvCDCqs3hJLcRyCuReI3oWvCZSMIMAPxeWj5owMLSWJKclj0cGHTBAIAAQYA5RJKdDQf6poAAAAASUVORK5CYII=')
    center center no-repeat;
  border-radius: size(20);
  border: size(3) solid $lighter-gray;
}

*::-webkit-scrollbar-corner {
  background-color: green;
}
