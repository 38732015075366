@import 'src/scss/mixins';
@import 'src/scss/variables';

.languageSwitcher {
  display: inline;
  text-align: right;
  cursor: pointer;
  margin-right: size(5);
  opacity: 0.6;
  width: size(74);
  height: size(74);
  &:last-child {
    margin-right: 0;
  }
}

.current {
  opacity: 1;
}
