@import 'src/scss/variables';
@import 'src/scss/mixins';

.base {
  background-color: $base-color;
  border: 2px solid $base-color;
  color: white;
  height: size(50);
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: size(50);
  padding: 0 size(30);
  display: flex;
  flex-basis: auto;
  align-items: center;
}

.inverted {
  background-color: transparent;
  color: $base-color;
  svg {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}

svg {
  height: size(20);
  width: auto;
}

.large {
  height: size(74);
  font-size: size(20);
  svg {
    height: size(35);
  }
}
