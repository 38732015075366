@import 'src/scss/mixins';

.searchCategories {
  list-style-type: none;
  margin: size(20) 0 0 0;
  padding: 0;
  li {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: size(20);
    line-height: size(20);
    background: url('./assets/arrow.svg') left size(2) no-repeat;
    padding-left: size(30);
    background-size: size(20) size(20);
  }
}
