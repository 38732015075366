@import 'src/scss/mixins';
@import 'src/scss/variables';

.search {
  input {
    height: size(74);
    font-size: 2rem;
    background: url(assets/search.svg) no-repeat size(20) center;
    background-size: size(37);
    padding-left: 4rem;
  }
}

.drawer {
  background-color: transparent !important;
}

.drawerHeader {
  height: size(240);
}

.drawerBody {
  position: relative;
  background-color: $lightest-gray;
  height: 100%;
}

.results {
  height: size(500);
  display: flex;
  padding: size(30);
}

.title {
  margin: 0 size(10) 0 0;
  display: inline;
  color: $base-color;
  text-transform: uppercase;
  font-size: 2rem;
}

.count {
  font-size: 1.2rem;
  font-weight: 600;
  color: $dark-gray;
  text-transform: uppercase;
}

.categories {
  width: size(400);
}

.products {
  flex-grow: 1;
}

.resultsList {
  margin-top: size(20);
  padding: 0 size(75);
  height: size(340);
  width: size(1450);
}

.prevArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  left: 0;
  top: size(130);
  svg {
    width: size(50);
    height: size(50);
  }
}

.nextArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 0;
  top: size(130);
  svg {
    width: size(50);
    height: size(50);
  }
}

.close {
  position: absolute;
  right: size(40);
  top: size(20);
}
