@import 'src/scss/mixins';
@import 'src/scss/variables';

.breadcrumbs {
  width: size($container-width);
  margin: 0 auto;
  height: size(60);
  line-height: size(60);
  a,
  span {
    font-size: size(20);
    color: $light-gray;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: $light-gray;
      text-decoration: none;
    }
    margin: 0 size(3);
  }
}

.active {
  color: $base-color !important;
}
