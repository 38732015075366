@import 'src/scss/mixins';
@import 'src/scss/variables';

.drawer {
  margin: size(40) !important;
  width: size(1840) !important;
}

.drawerBody {
}

.drawerFooter {
  button {
    margin: 0 auto;
  }
}

.imageSlider {
  display: flex;
}

.imageSlide {
  margin: 0 auto;
  padding: 0 size(470);
  img {
    height: size(900);
    width: size(900);
  }
}

.prevArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  left: size(100);
  top: size(400);
  svg {
    width: size(100);
    height: size(100);
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}

.nextArrow {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: size(100);
  top: size(400);
  svg {
    width: size(100);
    height: size(100);
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}
