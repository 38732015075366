@import 'src/scss/mixins';
@import 'src/scss/variables';

.productDetailsContainer {
  display: flex;
}

.productImages {
  width: size(530);
}

.productDetails {
  flex-grow: 1;
  padding: 0 size(20) 0 size(60);
  h4 {
    margin: size(20) 0 size(10) 0;
    font-size: 1.2rem;
    color: $dark-gray;
    text-transform: uppercase;
  }
}

.title {
  color: $dark-violet;
  font-size: 2.2rem;
  margin: 0 0 size(10) 0;
}

.article {
  color: $gray;
  font-size: 1.4rem;
  margin-bottom: size(20);
}

.price {
  color: $dark-violet;
  font-size: 2.4rem;
  font-weight: 600;
  position: relative;
  padding-right: size(10);
}

.currentPrice {
  color: red;
  margin-right: size(10);
  padding-right: size(10);
}

.oldPrice {
  text-decoration: line-through;
  color: $light-gray;
  font-size: 1.6rem;
  padding-right: size(10);
  font-weight: 400;
}

.sale {
  position: absolute;
  margin-left: size(20);
  display: inline-block;
  text-transform: uppercase;
  color: $dark-violet;
  background-color: $yellow;
  height: size(50);
  line-height: size(50);
  border-radius: size(25);
  padding: 0 size(30);
  border-bottom: size(2) solid darken($yellow, 10);
  font-size: size(20);
  font-weight: 700;
}

.scrollContainer {
  height: size(500);
  overflow-y: auto;
  padding-right: size(20);
  margin: size(20) 0;
}

.description {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: justify;
  color: $gray;
  line-height: 1.6rem;
}

.specifications {
  & > div {
    margin-bottom: size(5);
    font-size: 1.1rem;
    font-weight: 400;
    text-align: justify;
    color: $gray;
    line-height: 1.6rem;
  }
  span {
    display: inline-block;
    width: size(140);
  }
}
