@import 'src/scss/variables';
@import 'src/scss/mixins';

.locationList {
  width: size($container-width);
  margin: 0 auto;

  button {
    display: inline-block;
    width: 30%;
    margin: 5px;
    padding: 5px;
  }
}
