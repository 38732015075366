@import 'src/scss/mixins';
@import 'src/scss/variables';

.resultsList {
  position: relative;
  height: 100%;
  padding: size(50) 0;
}

.result {
  height: size(250);
  padding: 20px;
  position: relative;
}

.current {
  box-shadow: 0 0 size(20) 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 size(20) 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 size(20) 0 rgba(0, 0, 0, 0.1);
  &:before {
    content: '';
    position: absolute;
    left: size(-20);
    top: size(100);
    width: 0;
    height: 0;
    border-top: size(20) solid transparent;
    border-bottom: size(20) solid transparent;
    border-right: size(20) solid $light-pink;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: size(250);
    background-color: rgba(142, 49, 147, 0.15);
  }
}

.product {
  background-color: white;
  height: size(210);
}

.image {
  position: relative;
  img {
    position: relative;
    left: size(90);
    margin: size(20) size(10) size(10) size(10);
    width: size(100);
  }
}

.sale {
  display: inline-block;
  position: absolute;
  left: size(20);
  top: size(20);
  text-transform: uppercase;
  color: $dark-violet;
  background-color: $yellow;
  height: size(40);
  line-height: size(40);
  border-radius: size(20);
  padding: 0 size(20);
  border-bottom: size(2) solid darken($yellow, 10);
  font-size: size(16);
  font-weight: 600;
  z-index: 1;
}

.title {
  text-align: center;
  color: $dark-violet;
  margin: 0 0 size(10) 0;
  padding: 0 size(10);
  font-weight: 500;
  font-size: 1rem;
}

.price {
  color: $dark-violet;
  font-size: 120%;
  text-align: center;
}

.currentPrice {
  color: red;
  margin-right: size(10);
}

.oldPrice {
  text-decoration: line-through;
  color: $light-gray;
  font-size: 80%;
}

.prevArrow {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
  svg {
    width: size(50);
    height: size(50);
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}

.nextArrow {
  position: absolute;
  bottom: size(25);
  width: 100%;
  z-index: 1;
  text-align: center;
  svg {
    width: size(50);
    height: size(50);
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  }
}
