@import 'src/scss/variables';
@import 'src/scss/mixins';

.pagination {
  display: flex;
  justify-content: space-between;
  margin: size(15) 0 0 0;
  padding: 0;
  line-height: size(55);
  height: size(60);
  list-style-type: none;
}

.page {
  width: size(60);
  height: size(60);
  > a {
    display: inline-block;
    text-align: center;
    border-radius: size(50);
    width: size(60);
    height: size(60);
    font-size: 2rem;
    font-weight: 600;
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }
}

.active {
  background-color: $base-color;
  border-radius: size(20);
  a {
    color: white !important;
  }
}

.previous {
  width: size(60);
  height: size(60);
  > a {
    display: inline-block;
    width: size(60);
    height: size(60);
    background: transparent url('./assets/previous.svg') center center no-repeat;
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    background-size: size(40) size(40);
  }
}

.next {
  width: size(60);
  height: size(60);
  > a {
    display: inline-block;
    width: size(60);
    height: size(60);
    background: transparent url('./assets/next.svg') center center no-repeat;
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
    background-size: size(40) size(40);
  }
}
