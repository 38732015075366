@import 'src/scss/mixins';

.category {
  display: flex;
  align-items: flex-end;
  background-image: url('./assets/placeholder.png');
  > div {
    position: relative;
    border: size(1) solid white;
    height: 100%;
    flex-grow: 1;
    font-weight: 600;
    span {
      padding: size(50);
      position: absolute;
      bottom: 0;
      display: inline-block;
      color: white;
      -webkit-filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.5));
      filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.5));
    }
  }
}
