@import 'src/scss/mixins';
@import 'src/scss/variables';

.drawer {
  background-color: transparent !important;
}

.drawerHeader {
  height: size(170);
}

.drawerBreadcrumbs {
  background-color: white;
}

.drawerBody {
  background-color: $lighter-gray;
  height: 100%;
  display: flex;
}

.drawerDetails {
  width: size(1490);
  margin: size(50) size(40) 0 size(50);
}

.drawerSlider {
  background-color: white;
  width: size(340);
}
