@import 'src/scss/mixins';
@import 'src/scss/variables';

.product {
  position: relative;
  padding: size(10);
  text-align: center;
  width: size(360);
  max-width: size(360);
  height: size(320);
  min-width: size(250);
  background-color: white;
  border-radius: size(30);
  margin-bottom: size(20);
}

.compact {
  width: size(250);
  max-width: size(250);
  margin-right: size(10);
}

.image {
  padding: 0;
  img {
    margin: 0 auto;
    width: size(200);
  }
}

.sale {
  display: inline-block;
  position: absolute;
  left: size(20);
  top: size(20);
  text-transform: uppercase;
  color: $dark-violet;
  background-color: $yellow;
  height: size(40);
  line-height: size(40);
  border-radius: size(20);
  padding: 0 size(20);
  border-bottom: size(2) solid darken($yellow, 10);
  font-size: size(16);
  font-weight: 600;
}

.title {
  color: $dark-violet;
  margin: 0 0 size(10) 0;
  font-size: 1rem;
}

.article {
  color: $light-gray;
  margin-bottom: size(10);
  font-size: 1rem;
}

.price {
  color: $dark-violet;
  font-size: 1.2rem;
  font-weight: 700;
}

.currentPrice {
  color: red;
  margin-right: size(10);
}

.oldPrice {
  text-decoration: line-through;
  color: $light-gray;
  font-size: 1rem;
  font-weight: 500;
}
